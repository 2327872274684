type ValueParam = Parameters<Intl.NumberFormat['format']>['0']

export default (value: ValueParam, options?: Intl.NumberFormatOptions) => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'AED',
    notation: 'compact',
    maximumFractionDigits: 3,
    ...options,
  })

  return formatter.format(value)
}
